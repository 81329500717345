import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { openSnackbar } from "../../reusable/Notifier";

import { closeDialog, openDialog } from "../../reusable/ScrollablePopup";

import {
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Breadcrumbs,
  Box,
} from "@material-ui/core";

import { useMutation, useQuery } from "@apollo/client";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { MoreVert } from "@material-ui/icons";
import ProjectSummary from "./Tabs/ProjectSummary";
import ProjectDesign from "./Tabs/ProjectDesign";
// import ProjectPurchasing from "./Tabs/ProjectPurchasing";
import ProjectPM from "./Tabs/ProjectPM";
import ProjectQS from "./Tabs/ProjectQS";
import ProjectPeople from "./Tabs/ProjectPeople";
import ProjectDates from "./Tabs/ProjectDates";
import ProjectUpdates from "./Tabs/ProjectUpdates";
import ProjectActivity from "./Tabs/ProjectActivity";
import ProjectUpdateNameModal from "./ProjectUpdateNameModal";
import ProjectUpdateEgnyteLinksModal from "./ProjectUpdateEgnyteLinksModal";
import ProjectUpdateTeamLinksModal from "./ProjectUpdateTeamLinksModal";

import { GET_PROJECT, UPDATE_PROJECT } from "../../queries/Project";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Link } from "react-router-dom";
import { UserContext } from "../../utils/PageWrapper";

const StyledTabs = styled.div`
  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 25px;

    .tab {
      padding-bottom: 16px;
      cursor: pointer;
      transition: all ease-in-out 150ms;
      border-bottom: 2px solid transparent;

      &.active,
      &:hover {
        border-bottom: 2px solid rgb(243, 146, 0);
        font-weight: 500;
      }
    }
  }
`;

const tabs = [
  "Job Info",
  "People",
  "Dates",
  "Updates",
  "Design",
  "QS",
  "PM",
  // "Purchasing",
  "Activity",
];

const windowsPrepareEgnyteURL = (incoming) => {
  let clean = incoming.replaceAll("/", "!1");
  clean = clean.replaceAll(" ", "!2");
  return clean;
};

export default function SingleProject({
  match: {
    params: { project_id },
  },
  history,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [project, setProject] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: ({ updateProject }) => {
      openSnackbar({
        message: `Project has been updated.`,
        type: "success",
      });
      closeDialog();
      const newProject = { ...project, ...updateProject };
      setProject(newProject);
    },
    onError: () => {
      openSnackbar({
        message: "Problem updating project.",
        type: "error",
      });
    },
  });

  const { loading, error } = useQuery(GET_PROJECT, {
    variables: { project_id: project_id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    onCompleted: (data) => {
      setProject(data.getProject?.project);
      setProjectUsers(
        data.getProject?.projectUsers.map((user) => ({
          lead: user.lead,
          role: user.role,
          first_name: user.userInfo[0].first_name,
          last_name: user.userInfo[0].last_name,
        }))
      );
    },
  });

  const user = useContext(UserContext);

  const [currentTab, setCurrentTab] = useState("Job Info");

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  if (project == null) {
    return <Typography variant="h2">Could not find this project.</Typography>;
  }

  let tabView;

  // console.log("project", project);

  switch (currentTab) {
    case "Job Info":
    default:
      tabView = (
        <ProjectSummary
          project={project}
          projectUsers={projectUsers}
          updateProject={updateProject}
        />
      );
      break;
    case "People":
      tabView = <ProjectPeople project={project} />;
      break;
    case "Dates":
      tabView = (
        <Box style={{ paddingTop: "12px" }}>
          <ProjectDates
            department=""
            project_id={project_id}
            _id={project._id}
            title="Project Dates"
            setProject={setProject}
          />
        </Box>
      );
      break;
    case "Design":
      tabView = (
        <ProjectDesign project={project} updateProject={updateProject} />
      );
      break;
    case "Updates":
      tabView = <ProjectUpdates project={project} />;
      break;
    case "QS":
      tabView = <ProjectQS project={project} updateProject={updateProject} />;
      break;
    case "PM":
      tabView = <ProjectPM project={project} updateProject={updateProject} />;
      break;
    // case "Purchasing":
    //   tabView = <ProjectPurchasing />;
    //   break;
    case "Activity":
      tabView = <ProjectActivity project={project} />;
      break;
  }
  let egnytePathPrefix = "/Shared/Concorde Operations/01 Active Projects/";

  let egnyteURL =
    egnytePathPrefix +
    project?.job_no +
    " - " +
    project?.project_name +
    " - " +
    project?.address_1 +
    " - " +
    project?.companies_name;
  if (project?.egnytePath != null && project?.egnytePath.length > 0) {
    egnyteURL = project.egnytePath;
  }
  if (window.navigator.platform.indexOf("Mac") === -1) {
    //windows
    egnyteURL = "daffodilegnyte:Z:" + windowsPrepareEgnyteURL(egnyteURL);
  } else {
    //mac
    //2023-06-23 new Egnyte path is /Users/first.last/Library/CloudStorage/Enyte-cbgw
    //egnyteURL = "daffodilegnyte:/Users/daffodil?url=/Volumes/cbgw" + egnyteURL;
    egnyteURL = "daffodilegnyte:/Users/daffodil?url=/Users/"+user.first_name.toLowerCase().trim()+"."+user.last_name.toLowerCase().trim()+"/Library/CloudStorage/Egnyte-cbgw"+egnyteURL
  }

  return (
    <>
      <Helmet>
        <title>Project - {`${project?.project_name}`}</title>
      </Helmet>

      <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "14px" }}>
        {history.location.state?.fromSearch === true ? (
          <Link
            onClick={(e) => {
              e.preventDefault(); //needed to stop multiple forward entries
              history.goBack();
            }}
            style={{ color: "inherit" }}
          >
            Projects
          </Link>
        ) : (
          <Link to="/projects" style={{ color: "inherit" }}>
            Projects
          </Link>
        )}
        <Link
          style={{ color: "inherit" }}
          onClick={() => {
            setCurrentTab("Job Info");
          }}
        >
          {project?.job_no} - {project?.project_name}
        </Link>
        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          {currentTab}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={1} style={{ marginTop: "0" }}>
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            style={{
              marginBottom: "30px",
            }}
          >
            {project?.job_no} - {project?.project_name}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <div style={{ textAlign: "right" }}>
            <IconButton
              aria-label="microsoft teams"
              onClick={() => {
                if (project.teamsURL == null || project.teamsURL.length === 0) {
                  alert("There is no team for this project.");
                } else {
                  //'https://teams.microsoft.com/l/channel/19%3awX7edv-rKbpGmqJ0aV_Wx3a-bZU0oAba5VyxY3qPfm81%40thread.tacv2/General?groupId=c3c7a7fa-710a-4cea-a4d2-234a11e77f0b&tenantId=35da6ce6-fe23-4b99-8856-15a8b52a296c'
                  window.open(project.teamsURL, "_blank");
                }
              }}
            >
              <PeopleOutlineIcon />
            </IconButton>

            <IconButton
              aria-label="egnyte folder"
              onClick={() => window.open(egnyteURL, "_blank")}
            >
              <FolderOutlinedIcon />
            </IconButton>

            <IconButton
              aria-label="more vertical"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  if (
                    project.egnyteFolderURL == null ||
                    project.egnyteFolderURL.length === 0
                  ) {
                    alert("There is no egnyte folder for this project.");
                  } else {
                    window.open(project.egnyteFolderURL, "_blank");
                  }
                }}
              >
                Egnyte Web Folder
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  openDialog({
                    content: (
                      <ProjectUpdateNameModal
                        project={{
                          project_name: project.project_name,
                          _id: project._id,
                        }}
                      />
                    ),
                  });
                }}
              >
                Update Project Name
              </MenuItem>
              {user.role_id >= 10 && (
                <>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    openDialog({
                      content: (
                        <ProjectUpdateEgnyteLinksModal
                          job_no={project.job_no}
                        />
                      ),
                    });
                  }}
                >
                  Update Egnyte Links
                </MenuItem>
                <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  openDialog({
                    content: (
                      <ProjectUpdateTeamLinksModal
                        _id={project._id}
                      />
                    ),
                  });
                }}
              >
                Update Team Links
              </MenuItem>
              </>
              )}
            </Menu>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <StyledTabs>
          <div className="tabs-wrapper">
            {tabs.map((tabName) => (
              <div
                key={tabName}
                onClick={() => {
                  setCurrentTab(tabName);
                }}
                style={{
                  paddingLeft: "0.8em",
                  paddingRight: "0.6em",
                }}
              >
                <Typography
                  component="h3"
                  variant="subtitle1"
                  className={currentTab === tabName ? "tab active" : "tab"}
                  color={currentTab === tabName ? "secondary" : "textSecondary"}
                >
                  {tabName}
                </Typography>
              </div>
            ))}
          </div>
        </StyledTabs>
      </Grid>
      {tabView}
    </>
  );
}
