import gql from "graphql-tag";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import validator from "validator";

export const labelColour = "#626C79";

export const projectStatuses = [
  "Proposed",
  "Tender",
  "On Hold",
  "Approved",
  "Active",
  "Design Only",
  "Handed Over",
  "Completed",
  "Withdrawn",
  "Archived",
];

export const emailValidation = (value) => {
  let redefinedValue = value?.emailAddress || value;

  return redefinedValue &&
    validator.isEmail(redefinedValue) &&
    !redefinedValue.includes(".con") &&
    !redefinedValue.includes(".coma")
    ? undefined
    : "Invalid Email";
};

export function useHistoryState(key, initialValue) {
  const history = useHistory();
  const [rawState, rawSetState] = useState(() => {
    const value = history.location.state?.[key];
    return value ?? initialValue;
  });
  function setState(value) {
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        [key]: value,
      },
    });
    rawSetState(value);
  }
  return [rawState, setState];
}

export const requiredLabel = "Info Required";

export const poundUK = Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const UPDATE_EGNYTE_LINKS = gql`
  mutation updateEgnyteLinks($job_no: Int) {
    updateEgnyteLinks(job_no: $job_no)
  }
`;

export const UPDATE_TEAM_LINKS = gql`
  mutation updateTeamLinks($_id: ID) {
    updateTeamLinks(_id: $_id)
  }
`;

export const GET_ADDITIONAL_RECIPIENTS = gql`
  query getAdditionalRecipients($filter: String) {
    getAdditionalRecipients(filter: $filter) {
      _id
      emailAddress
      isUser
    }
  }
`;

export const GET_PROJECT = gql`
  query getProject($project_id: String) {
    getProject(project_id: $project_id) {
      project {
        _id
        project_id
        job_no
        project_name
        companies_name
        design_budget
        egnyteFolderURL
        egnytePath
        teamsURL
        eque2Invoices {
          name
          dateCreated
          value
          title
        }
        project_status_text
        start_on_site
        address_1
        address_2
        city
        postcode
        concorde_offfice
        scope_of_works
        project_id
        client
        services_design
        services_qs
        services_ff
        services_build
        services_hs
        contract_budget
        client_budget
        project_budget_total
        ff_budget
        expected_margin
        contract_total
        remaining_to_invoice
        total_invoiced
        ffEntries {
          name
          value
        }
        est_handover_date
        practical_completion_date
        practical_completion
        hs_returned_date
        hs_returned
        final_accounts
        final_accounts_date
        final_accounts_yesno
        final_accounts_agreed_by
        final_accounts_agreed_by_name
        om_manuals_date
        om_manuals
        retention
        retention_date
        retention_yesno
        includeFFInClientDirect
        client_budget_final
      }

      projectUsers {
        _id
        role
        lead
        userInfo {
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_PROJECT_ACTIVITIES = gql`
  query getActivities($projectId: ID!, $content: String) {
    getActivities(projectId: $projectId, content: $content) {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
  }
`;

export const GET_UPDATES = gql`
  query getProjectUpdates(
    $project_job_no: Int
    $start_date: DateTime
    $end_date: DateTime
    $companies_name: String
    $the_update: String
  ) {
    getProjectUpdates(
      project_job_no: $project_job_no
      start_date: $start_date
      end_date: $end_date
      companies_name: $companies_name
      the_update: $the_update
    ) {
      userInfo {
        first_name
        last_name
      }
      date_time_added
      the_update
      project_job_no
      project_name
      companies_name
      project_id
      recipients
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $_id: String!
    $scope_of_works: String
    $services_design: Boolean
    $services_qs: Boolean
    $services_ff: Boolean
    $services_build: Boolean
    $services_hs: Boolean
    $design_budget: Float
    $client_budget: Float
    $contract_budget: Float
    $ff_budget: Float
    $expected_margin: Int
    $ffEntries: [JSON]
    $start_on_site: DateTime
    $est_handover_date: DateTime
    $project_status_text: String
    $address_1: String
    $address_2: String
    $city: String
    $postcode: String
    $practical_completion_date: String
    $practical_completion: Boolean
    $hs_returned_date: String
    $hs_returned: Boolean
    $final_accounts_date: String
    $final_accounts_yesno: Boolean
    $final_accounts: Float
    $om_manuals_date: String
    $om_manuals: Boolean
    $retention_date: String
    $retention_yesno: Boolean
    $retention: Float
    $final_accounts_agreed_by: ID
    $final_accounts_agreed_by_name: String
    $includeFFInClientDirect: Boolean
  ) {
    updateProject(
      _id: $_id
      scope_of_works: $scope_of_works
      services_design: $services_design
      services_qs: $services_qs
      services_ff: $services_ff
      services_build: $services_build
      services_hs: $services_hs
      design_budget: $design_budget
      client_budget: $client_budget
      contract_budget: $contract_budget
      ff_budget: $ff_budget
      expected_margin: $expected_margin
      ffEntries: $ffEntries
      start_on_site: $start_on_site
      est_handover_date: $est_handover_date
      project_status_text: $project_status_text
      address_1: $address_1
      address_2: $address_2
      city: $city
      postcode: $postcode
      practical_completion_date: $practical_completion_date
      practical_completion: $practical_completion
      hs_returned_date: $hs_returned_date
      hs_returned: $hs_returned
      final_accounts_date: $final_accounts_date
      final_accounts_yesno: $final_accounts_yesno
      final_accounts: $final_accounts
      om_manuals_date: $om_manuals_date
      om_manuals: $om_manuals
      retention_date: $retention_date
      retention_yesno: $retention_yesno
      retention: $retention
      final_accounts_agreed_by: $final_accounts_agreed_by
      final_accounts_agreed_by_name: $final_accounts_agreed_by_name
      includeFFInClientDirect: $includeFFInClientDirect
    ) {
      _id
      scope_of_works
      services_design
      services_qs
      services_ff
      services_build
      services_hs
      design_budget
      client_budget
      contract_budget
      project_budget_total
      ff_budget
      expected_margin
      contract_total
      remaining_to_invoice
      total_invoiced
      ffEntries {
        name
        value
      }
      start_on_site
      est_handover_date
      project_status_text
      address_1
      address_2
      city
      postcode
      practical_completion_date
      practical_completion
      hs_returned_date
      hs_returned
      final_accounts_date
      final_accounts_yesno
      final_accounts
      om_manuals_date
      om_manuals
      retention_date
      retention_yesno
      retention
      final_accounts_agreed_by
      final_accounts_agreed_by_name
      includeFFInClientDirect
      client_budget_final
      project_id
      job_no
      project_name
      eque2Invoices {
        name
        dateCreated
        value
      }
    }
  }
`;

export const UPDATE_PROJECT_NAME = gql`
  mutation updateProject($_id: String!, $project_name: String!) {
    updateProject(_id: $_id, project_name: $project_name) {
      _id
      project_name
    }
  }
`;
