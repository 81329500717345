import ProjectDates from "./ProjectDates";
import React from "react";
import { Typography, Grid, Card, Tooltip, IconButton } from "@material-ui/core";
import ProjectCostsModal from "../ProjectCostsModal";
import CreateIcon from "@mui/icons-material/Create";
import { openDialog } from "../../../reusable/ScrollablePopup";
import { poundUK, requiredLabel } from "../../../queries/Project";
import ProjectDesignInvoicesTable from "../ProjectDesignInvoicesTable";
import { format } from "date-fns";
import { primaryColor } from "../../../assets/ThemeStyling";

function ProjectPMDate({ date, yesno, label, hasfigure, figure }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span>{label}</span>
      {yesno && <span>Agreed</span>}
      <span>{date ? format(new Date(date), "dd/MM/yyyy") : requiredLabel}</span>
      {hasfigure && <span>{poundUK.format(figure ? figure : 0)}</span>}
    </div>
  );
}

export default function ProjectQS({ project, updateProject }) {
  const figureStyle = { textAlign: "right" };
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card
          style={{
            padding: "0",
            width: "100%",
          }}
        >
          <Grid
            item
            xs
            style={{
              padding: "18px 24px",
              backgroundColor: "#4B4F54",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              Project Costs Overview
              <Tooltip title="Edit" placement="top">
                <IconButton
                  onClick={() => {
                    openDialog({
                      content: (
                        <ProjectCostsModal
                          project={project}
                          onSaveClicked={(newProject) => {
                            newProject.client_budget = parseFloat(
                              newProject.client_budget
                            );
                            updateProject({
                              variables: {
                                ...newProject,
                                _id: project._id,
                              },
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <CreateIcon style={{ fill: primaryColor }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid
            container
            className="MuiTypography-body1"
            spacing={3}
            style={{ padding: "1em" }}
          >
            <Grid item xs={6} md={6}>
              Design Fees
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.design_budget ? project.design_budget : 0
              )}
            </Grid>
            <Grid item xs={6} md={6} style={{ whiteSpace: "nowrap" }}>
              Client Direct {project.includeFFInClientDirect ? "(inc F&F)" : ""}
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.client_budget_final ? project.client_budget_final : 0
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              Final Account
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.final_accounts ? project.final_accounts : 0
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              Contract Budget
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.contract_budget ? project.contract_budget : 0
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              Project Total
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.project_budget_total ? project.project_budget_total : 0
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              Agreed By
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
              {project.final_accounts_agreed_by_name}
            </Grid>
            <Grid item xs={6} md={6}>
              F&amp;F Total
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(project.ff_budget ? project.ff_budget : 0)}
            </Grid>

            <Grid item xs={6} md={6}>
              Expected Margin
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {project.expected_margin}%
            </Grid>
            <Grid item xs={6} md={6} style={{ whiteSpace: "nowrap" }}>
              Contract Total{" "}
              {project.includeFFInClientDirect ? "" : "(inc F&F)"}
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.contract_total ? project.contract_total : 0
              )}
            </Grid>

            <Grid item xs={6} md={6} style={{ whiteSpace: "nowrap" }}>
              Remaining to Invoice
            </Grid>
            <Grid item xs={6} md={6} style={figureStyle}>
              {poundUK.format(
                project.remaining_to_invoice ? project.remaining_to_invoice : 0
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <ProjectPMDate
                date={project.retention_date}
                yesno={project.retention_yesno}
                label="Retention"
                hasfigure={true}
                figure={project.retention}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card style={{ padding: "0", width: "100%" }}>
          <Grid
            item
            xs
            className="MuiTypography-body1"
            style={{
              padding: "26px 24px",
              backgroundColor: "#4B4F54",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              Invoices Raised
              <Typography

              // gutterBottom
              >
                Total Invoiced:{" "}
                {poundUK.format(
                  project.total_invoiced ? project.total_invoiced : 0
                )}
              </Typography>
            </Typography>
          </Grid>
          <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
            <ProjectDesignInvoicesTable invoices={project.eque2Invoices} />
          </div>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <ProjectDates
          project_id={project.project_id}
          department=""
          title="Project Dates"
        />
      </Grid>
    </Grid>
  );
}
