import React, { useState, Fragment } from "react";

import MUIDataTable from "mui-datatables";
import moment from "moment";
import gql from "graphql-tag";
import { Tooltip, IconButton, createTheme } from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";

import { useMutation } from "@apollo/client";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider } from "@material-ui/styles";
import NewProjectDateModal from "./NewProjectDateModal";
import { openDialog } from "../../reusable/ScrollablePopup";
import { secondaryColor } from "../../assets/ThemeStyling";
import { actionsLabel, detailsLabel } from "../../assets/GlobalLabels";

const greyColor = "#626C79";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(3)": {
          width: 140,
        },
        "&:nth-child(4)": {
          width: 140,
        },
        "&:nth-child(5)": {
          width: 130,
        },
      },
    },
  },
});

// const theme = createMuiTheme({
//   overrides: {
//     MuiTable: {
//       root: {
//         border: [[0, "solid", "#d3d3d3"]],
//       },
//     },
//   },
// });

const REMOVE_PROJECT_DATE = gql`
  mutation removeProjectDate($id: String!) {
    removeProjectDate(id: $id) {
      _id
      start_on_site
      est_handover_date
    }
  }
`;

export default function ProjectDatesTable({ dates, onRemoved, _id }) {
  const [
    removeProjectDate,
    {
      loading: removeProjectDateLoading,
      error: removeProjectDateError,
      data: removeProjectDateData,
    },
  ] = useMutation(REMOVE_PROJECT_DATE, {
    onError: () => {
      openSnackbar({
        message: "Error removing event, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Event removed successfully",
        type: "success",
      });
      onRemoved();
    },
  });

  // console.log("dates", dates);

  const [anchorEl, setAnchorEl] = useState(null);
  const tableDataArray = [];

  //we need to make sure that dates which have already passed appear at the bottom of the table
  //to do that we will add 100 years to any date which has passed
  //we will then use the custom render for the field to subtract that 100 years when rendering
  const now = new Date();
  now.setUTCHours(0, 0, 0, 0);
  const nowSeconds = now.getTime() / 1000;
  dates.forEach((date) => {
    let leadingChar = "a";
    const thisDate = moment(date.date);
    if (thisDate.unix() < nowSeconds) {
      leadingChar = "z";
    }
    const data = [
      date.event,
      date.details,
      date.department,
      leadingChar + thisDate.format("YYYY-MM-DD"),
      date._id,
    ];
    tableDataArray.push(data);
  });

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: "Date",
      direction: "asc",
    },
  };

  const columnsArray = [
    {
      name: "Event",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const data = tableDataArray[dataIndex];
          const value = data[0];
          if (data[3].indexOf("a") === 0) {
            return value;
          } else {
            return <span style={{ color: greyColor }}>{value}</span>;
          }
        },
      },
    },
    {
      name: detailsLabel,
      options: {
        customBodyRenderLite: (dataIndex) => {
          const data = tableDataArray[dataIndex];
          const value = data[1];
          if (data[3].indexOf("a") === 0) {
            return value;
          } else {
            return <span style={{ color: greyColor }}>{value}</span>;
          }
        },
      },
    },
    {
      name: "Department",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const data = tableDataArray[dataIndex];
          const value = data[2];
          if (data[3].indexOf("a") === 0) {
            return value;
          } else {
            return <span style={{ color: greyColor }}>{value}</span>;
          }
        },
      },
    },
    {
      name: "Date",
      options: {
        customBodyRender: (value) => {
          value = value.substring(1);
          const thisDate = moment(value);
          const formattedDate = thisDate.format("DD/MM/YYYY");
          if (thisDate.unix() < nowSeconds) {
            return <span style={{ color: greyColor }}>{formattedDate}</span>;
          } else {
            return formattedDate;
          }
        },
        sort: true,
        display: "true",
      },
    },
    {
      name: actionsLabel,
      options: {
        sort: false,
        display: "true",
        customBodyRenderLite: (dataIndex) => (
          <>
            <Tooltip title="Edit Date" placement="top">
              <IconButton
                onClick={() => {
                  openDialog({
                    content: (
                      <NewProjectDateModal
                        value={dates[dataIndex]._id}
                        // project_id={project_id}
                        _id={_id}
                        onAdded={() => {
                          onRemoved();
                        }}
                      />
                    ),
                  });

                  setAnchorEl(null);
                }}
              >
                <CreateIcon style={{ fill: secondaryColor }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove Date" placement="top">
              <IconButton
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to remove this event?"
                    )
                  ) {
                    removeProjectDate({
                      variables: {
                        id: dates[dataIndex]._id,
                      },
                    });
                  }
                  setAnchorEl(null);
                }}
              >
                <DeleteIcon style={{ fill: secondaryColor }} />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
    </ThemeProvider>
  );
}
